import { NgModule } from "@angular/core";
import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { split } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { setContext } from "apollo-link-context";
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import * as introspectionQueryResultData  from './fragmentTypes.json';

console.log ("LOG", introspectionQueryResultData);
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData:(introspectionQueryResultData as any).default
});
const uri = "https://graphql.datocms.com/preview"; //our test Graphql Server which returns rates

export function createApollo(httpLink: HttpLink) {
  // Get the authentication token from local storage if it exists
  const token = "eba74cd8bd41ff4ce0e893cde1256e"; // localStorage.getItem("token");
  const auth = setContext((operation, context) => ({
    headers: {
      Authorization: `Bearer ${token}`
    }
  }));
  //https://graphql.datocms.com/preview
  const link = ApolloLink.from([auth, httpLink.create({ uri })]);
  return {
    link: link,
    cache:  new InMemoryCache({ fragmentMatcher })
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}

/*
fetch(`${uri}`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json', "Authorization": "Bearer eba74cd8bd41ff4ce0e893cde1256e" },
  body: JSON.stringify({
    variables: {},
    query: `
      {
        __schema {
          types {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      }
    `,
  }),
})
  .then(result => result.json())
  .then(result => {
    // here we're filtering out any type information unrelated to unions or interfaces
    const filteredData = result.data.__schema.types.filter(
      type => type.possibleTypes !== null,
    );
    result.data.__schema.types = filteredData;
    console.log("FRAGMENT.JSON", JSON.stringify(result.data))


  });
  */