import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: 'app-repair-partner',
  templateUrl: './repair-partner.component.html',
  styleUrls: ['./repair-partner.component.css']
})
export class RepairPartnerComponent implements OnInit {
  
  @Input() bloc: any;

  items : any[] =[];

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        configurationGlobale {
          technos {
            url
          }
        }
      }
      
      `
    })
    .valueChanges.subscribe(result => {
      this.items = result.data.configurationGlobale.technos;
      console.log("ok", this.items);
    });
  }

  repairCarousel: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    responsive: {
      0:{
        items:4,
      },
      768:{
        items:6,
      },
      1200:{
        items:6,
      }
    }
  }

}
