import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
declare let $: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  constructor(private apollo: Apollo) {}

  config: any = {};
  footer: any = {};

  ngOnInit() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          query MyQuery {
            configurationGlobale {
              societe
              technos {
                url
              }
              footerTexte
              copyright
              footer {
                adresse
                email
                telephone
                lienFacebook
                lienTwitter
                lienInstagram
                lienLinkedin
              }
            }
          }
        `
      })
      .valueChanges.subscribe(result => {
        console.log("ok", result);
        this.config = result.data;
        this.footer = result.data.configurationGlobale.footer[0];
        //result.data.configurationGlobale.copyright
        // this.rates = result.data && (result.data as any).rates;
        // this.loading = result.loading;
      });

    // Go to Top
    $(function() {
      //Scroll event
      $(window).on("scroll", function() {
        var scrolled = $(window).scrollTop();
        if (scrolled > 300) $(".go-top").fadeIn("slow");
        if (scrolled < 300) $(".go-top").fadeOut("slow");
      });
      //Click event
      $(".go-top").on("click", function() {
        $("html, body").animate({ scrollTop: "0" }, 500);
      });
    });
  }
}
