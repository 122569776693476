import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: 'app-iot',
  templateUrl: './iot.component.html',
  styleUrls: ['./iot.component.css']
})
export class IotComponent implements OnInit {

  blocs : any[] = [];


  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        configurationGlobale {
          id
          miniblocs {
            __typename
            ... on BlocPrincipalRecord {
              id
              titre
              sousTitre
              texte
              afficherLeBouton
              texteBouton
              lienBouton
            }
            ... on BlocAnnonceRecord {
              id
            }
            ... on BlocTechnoRecord {
              id
              titre
              separateur
              texte
            }
            ... on BlocService01Record {
              id
              chip
              titre
              separateur
              texte
            }
            ... on TextesImageRecord {
              afficherBouton
              background
              separator
              images {
                url
              }
              labelDuBouton
              lienDuBouton
              texte
              titre
              id
              cssClasses
              inverse
              backgroundColor 
              {
                hex
              }
              decorationCerclesConcentriques
              decorationWireGrid
            }

          }
        }
      }
      
      `, "fetchPolicy": 'no-cache'
    })
    .valueChanges.subscribe(result => {
      this.blocs = result.data.configurationGlobale.miniblocs;
      console.log("ok", this.blocs);
    });
  }

}
