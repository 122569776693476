import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

declare let $: any;

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {

  info: any = {};
  // team:any  = undefined;

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`
        query MyQuery {
            configurationGlobale {
              mentionsLegales
              mentionsLegalesTitre
            }
          }
      `
    })
    .valueChanges.subscribe(result => {
      this.info = result.data.configurationGlobale;
console.log(result.data);


    });
  }

}
