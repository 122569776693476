import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private apollo: Apollo) { }
  about:any = undefined;
  aboutInfo:any = undefined;
  funFacts:any = undefined;

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        configurationGlobale {
          aboutUsTitre
          aboutUsDescription
          about {
            titre
            description
            image {
              url
            }
            image2 {
              url
            }
            blocCentreTexte
            blocCentreTitre
            blocDroiteTexte
            blocDroiteTitre
            blocGaucheTexte
            blocGaucheTitre
          }
          team {
            titre
            description
          }
          teamMembers {
            description
            fonction
            nomPrenom
            photo {
              url
            }
          }

          funfacts {
            titre
            separateur
            texte
            label01
            valeur01
            label02
            valeur02
            label03
            valeur03
            label04
            valeur04
            label05
            valeur05
            label06
            valeur06
          }

        }
      }
      
      `
    })
    .valueChanges.subscribe(result => {
      this.about = result.data.configurationGlobale;
      this.aboutInfo = this.about.about[0];
      if(this.about.funfacts && this.about.funfacts.length > 0) {
        this.funFacts = this.about.funfacts[0];
      }
      console.log("ok", this.about);
    });
  }

}
