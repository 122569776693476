import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FeaturesComponent } from './features/features.component';
import { FeaturesDetailsComponent } from './features-details/features-details.component';
import { ServicesComponent } from './services/services.component';
import { ServicesDetailsComponent } from './services-details/services-details.component';
import { ProjectComponent } from './project/project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { TeamComponent } from './team/team.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { RepairComponent } from './repair/repair.component';
import { IotComponent } from './iot/iot.component';
import { AnnoncesComponent } from './annnonces/annonces.component';
import { AnnonceDetailsComponent } from './annonce-details/annonce-details.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';


const routes: Routes = [
  // { path: '', redirectTo: '/home', pathMatch: 'full' }, // for defaault redirecting
  { path: '', component: IotComponent },
  { path: 'startup', component: HomeComponent },
  { path: 'developers', component: DevelopersComponent },
  { path: 'web-hosting', component: WebHostingComponent },
  { path: 'repair', component: RepairComponent },
  { path: 'iot', component: IotComponent },
  { path: 'about', component: AboutComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'features-details', component: FeaturesDetailsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'services/:slug', component: ServicesDetailsComponent },
  { path: 'project', component: ProjectComponent },
  { path: 'projet/:slug', component: ProjectDetailsComponent },
  { path: 'annonces', component: AnnoncesComponent },
  { path: 'annonces/:slug', component: AnnonceDetailsComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  { path: 'team', component: TeamComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-details', component: BlogDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
