import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { ActivatedRoute, Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.css']
})
export class ServicesDetailsComponent implements OnInit {


  blocs : any[] = [];
  service : any;

  constructor(private apollo: Apollo, private route: ActivatedRoute) { }



  ngOnInit() {


    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery($slug:String!) {
        service(filter: {slug: {eq:$slug }}) {
          titre
          miniblocs {
            __typename
            ... on BlocMediaRecord {
              id
              medias {
                customData(locale: en)
                url
              }
            }
            ... on MiniBlocProjetRecord {
              id
              texte
              titre
            }
            ... on BlocImageRecord {
              image {
                url
              }
            }
            ... on TextesImageRecord {
              afficherBouton
              background
              separator
              images {
                url
              }
              labelDuBouton
              lienDuBouton
              texte(markdown: true)
              titre
              id
              cssClasses
              inverse
            }
          }
        }
      }
      `, variables : {
        slug: this.route.snapshot.params.slug
      }
      , "fetchPolicy": 'no-cache'
    })
    .valueChanges.subscribe(result => {
      this.blocs = result.data.service.miniblocs;
      this.service = result.data.service;
      console.log("ok", this.blocs);
      setTimeout(() => {
        
      $('.popup-youtube').magnificPopup({
        disableOn: 320,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
      });
      
      $('.popup-btn').magnificPopup({
            type: 'image',
            gallery:{
                enabled:true
            }
      });
      }, 1);

    });


    // FAQ Accordion
    $(function() {
      $('.accordion').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');		
      });
    });
  }

}
