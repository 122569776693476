import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  rates: any[];
  loading = true;
  error: any;
  //copyright : string;
  config : any;

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          query MyQuery {
            configurationGlobale {
              societe
              technos {
                url
              }
              footerTexte
              copyright
            }
          }
        `
      })
      .valueChanges.subscribe(result => {
        console.log("ok", result);
        //result.data.configurationGlobale.copyright
        // this.rates = result.data && (result.data as any).rates;
        // this.loading = result.loading;
        this.config = result;
        this.error = (result as any).error;
      });
  }
}
