
import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import { ActivatedRoute, Router } from '@angular/router';
import gql from "graphql-tag";

declare var $: any;

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

  projet : any = undefined;

  constructor(private apollo: Apollo, private route: ActivatedRoute) {  }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery($slug:String!) {
        projet(filter: {slug: {eq:$slug }}) {
          id
          titre
          descriptionCourte
          medias {
            url
          }
          libelleBouton
          lienBouton
          miniblocs {
            titre
            texte
          }
        }
      }
      
      `, variables : {
        slug: this.route.snapshot.params.slug
      }
    })
    .valueChanges.subscribe(result => {
      this.projet = result.data.projet;
      console.log("ok", this.projet);
    });


    $('.popup-youtube').magnificPopup({
			disableOn: 320,
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false
    });
    
    $('.popup-btn').magnificPopup({
          type: 'image',
          gallery:{
              enabled:true
          }
    });
  }

}
