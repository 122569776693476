import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  projets : any[] = [];

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        allProjets {
          id
          titre
          descriptionCourte
          detailpageavailable
          medias {
            url
          }
          slug
        }
      }
      `
    })
    .valueChanges.subscribe(result => {
      this.projets = result.data.allProjets;
      console.log("ok", this.projets);
    });
  }

}
