import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

declare let $: any;

@Component({
  selector: 'app-annonces',
  templateUrl: './annonces.component.html',
  styleUrls: ['./annonces.component.css']
})
export class AnnoncesComponent implements OnInit {

  annonces : any[] = [];
  //team:any  = undefined;
  annoncesInfo : any = {};

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        allAnnonces(filter: {active: {eq: "true"}}) {
          id
          slug
          mettreEnAvant
          titre
          descriptionCourte(markdown: true)
          medias {
            url
          }
        }
        configurationGlobale {
          miniblocs {
            ... on BlocAnnonceRecord {
              id
              titre
              description(markdown: false)
            }
          }
        }
      }
      `
    })
    .valueChanges.subscribe(result => {
      this.annonces = result.data.allAnnonces;



    });
  }

}
