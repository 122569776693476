import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: 'app-team-home',
  templateUrl: './home-team.component.html',
  styleUrls: ['./home-team.component.css']
})
export class HomeTeamComponent implements OnInit {

  members : any[] = [];
  team:any  = undefined;

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        configurationGlobale {
          team {
            titre
            description
          }
          teamMembers {
            description
            fonction
            nomPrenom
            photo {
              url
            }
          }
        }
      }
      
      `
    })
    .valueChanges.subscribe(result => {
      this.members = result.data.configurationGlobale.teamMembers;
      this.team = result.data.configurationGlobale.team[0];
      console.log("TEAM : ", this.team);
    });
  }

  customOptions: OwlOptions = {
    loop: true,
		nav: false,
		dots: true,
		autoplay: false,
		smartSpeed: 1000,
		autoplayTimeout: 5000,
		responsive: {
			0:{
        items:1,
      },
      768:{
        items:2,
      },
      1200:{
        items:4,
			},
      1500:{
        items:5,
			}
		}
  }

}
