import { Component, Input, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: "app-bloc-service01",
  templateUrl: "./bloc-service01.component.html",
  styleUrls: ["./bloc-service01.component.css"]
})
export class BlocService01Component implements OnInit {

  @Input() blocService01: any;
  servicesInfo : any[] = [];


  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          query MyQuery {
            allServices(filter: {groupe: {eq: "Groupe1"}, active: {eq: "true"}}) {
              id
              groupe
              slug
              mettreEnAvant
              titre
              descriptionCourte(markdown: true)
              icone
              medias {
                url
              }
            }
            configurationGlobale {
              miniblocs {
                ... on BlocAnnonceRecord {
                  id
                  titre
                  description(markdown: false)
                }
              }
            }
          }
        `
      })
      .valueChanges.subscribe(result => {
        this.servicesInfo = result.data.allServices || [];
      });
  }
}
