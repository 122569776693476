import { Component, OnInit } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
// import { FormsModule } from '@angular/forms';

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"]
})

export class ContactComponent implements OnInit {

  // Base url
  //baseurl = "https://us-central1-adixoncomingsoon.cloudfunctions.net"; ///notifyMe?email=coucou6';
  //baseurl = "http://localhost:5000/adixon-website-2020/us-central1"; ///notifyMe?email=coucou6';
  baseurl = "https://us-central1-adixon-website-2020.cloudfunctions.net"; ///notifyMe?email=coucou6';

  contact: any = undefined;
  submitted: boolean = false;
  contactRequestDisabled: boolean = false;

  demandeDeContact  = {
    Nom: "",
    Email: "",
    Telephone: "",
    Objet: "",
    Message: ""
  };

  constructor(private apollo: Apollo, private http: HttpClient) {}

  ngOnInit() {
    this.apollo
      .watchQuery<any>({
        query: gql`
          query MyQuery {
            configurationGlobale {
              pageContact {
                titre
                labelEmail
                email
                labelTelephone
                telephone
                labelAdresse
                adresse
                boutonEnvoyer
                separateur
                sousTitre
                texte
                image { url }
              }
            }
          }
        `
      })
      .valueChanges.subscribe(result => {
        this.contact = result.data.configurationGlobale.pageContact[0];
      });
  }

  async sendContactRequest() {
    console.log("notifyMe", this.demandeDeContact);
    this.contactRequestDisabled = true;
    
    await this.http
      .post(this.baseurl + "/sendContactFormHttp", this.demandeDeContact)
      .pipe(retry(1), catchError(this.errorHandl))
      .subscribe(res => {
        console.log("ok");
        this.submitted = true;
        this.contactRequestDisabled = false;
      });
  }
  // Error handling
  errorHandl(error) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    this.contactRequestDisabled = false;
    return throwError(errorMessage);
  }
}
