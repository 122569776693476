import { Component, OnInit } from '@angular/core';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
declare var $: any;

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {


  blocs : any[] = [];


  services : any[] = [];
  //team:any  = undefined;
  servicesInfo : any = {};

  constructor(private apollo: Apollo) { }

  ngOnInit() {

    $('.popup-youtube').magnificPopup({
			disableOn: 320,
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false
    });
    
    $('.popup-btn').magnificPopup({
          type: 'image',
          gallery:{
              enabled:true
          }
    });


    this.apollo
    .watchQuery<any>({
      query: gql`query MyQuery {
        allServices(filter: {active: {eq: "true"}}) {
          id
          slug
          mettreEnAvant
          titre
          descriptionCourte(markdown: true)
          icone
          medias {
            url
          }
        }
        configurationGlobale {
          miniblocs {
            ... on BlocAnnonceRecord {
              id
              titre
              description(markdown: false)
            }
          }
          pageservicesminiblocs {
            __typename
            ... on BlocService01Record {
              id
              chip
              titre
              separateur
              texte
            }
            ... on BlocService02Record {
              id
              titre
              separateur
              texte
            }
            ... on TextesImageRecord {
              afficherBouton
              background
              separator
              images {
                url
              }
              labelDuBouton
              lienDuBouton
              texte
              titre
              id
              cssClasses
              inverse
              backgroundColor 
              {
                hex
              }
              decorationCerclesConcentriques
              decorationWireGrid
            }

          }
        }
      }
      `
    })
    .valueChanges.subscribe(result => {
      this.services = result.data.allServices;
      this.blocs = result.data.configurationGlobale.pageservicesminiblocs;
    });
  }

}
