import { Component, Input, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: "app-bloc-image",
  templateUrl: "./bloc-image.component.html",
  styleUrls: ["./bloc-image.component.css"]
})
export class BlocImageComponent implements OnInit {

  @Input() blocImage: any;
  // servicesInfo : any = {};


  constructor(private apollo: Apollo) {}

  ngOnInit() {
    // this.apollo
    //   .watchQuery<any>({
    //     query: gql`
    //       query MyQuery {
    //         allServices(filter: {groupe: {eq: "Groupe2"}, active: {eq: "true"}}) {
    //           id
    //           slug
    //           mettreEnAvant
    //           titre
    //           descriptionCourte(markdown: true)
    //           icone
    //           theme
    //           medias {
    //             url
    //           }
    //         }
    //         configurationGlobale {
    //           miniblocs {
    //             ... on BlocAnnonceRecord {
    //               id
    //               titre
    //               description(markdown: false)
    //             }
    //           }
    //         }
    //       }
    //     `
    //   })
    //   .valueChanges.subscribe(result => {
    //     this.servicesInfo = result.data.allServices;
    //   });
  }
}
