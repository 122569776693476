import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

@Component({
  selector: 'app-bloc-principal',
  templateUrl: './bloc-principal.component.html',
  styleUrls: ['./bloc-principal.component.css']
})
export class BlocPrincipalComponent implements OnInit {

  @Input() blocPrincipal: any;


  annonces : any[] = [];
  //team:any  = undefined;
  annoncesInfo : any = {};

  constructor(private apollo: Apollo) { }

  ngOnInit() {
    // this.apollo
    // .watchQuery<any>({
    //   query: gql`query MyQuery {
    //     allAnnonces(filter: {mettreEnAvant: {eq: "true"}}) {
    //       id
    //       slug
    //       mettreEnAvant
    //       titre
    //       descriptionCourte(markdown: true)
    //       medias {
    //         url
    //       }
    //     }
    //     configurationGlobale {
    //       miniblocs {
    //         ... on BlocAnnonceRecord {
    //           id
    //           titre
    //           description(markdown: false)
    //         }
    //       }
    //     }
    //   }
    //   `
    // })
    // .valueChanges.subscribe(result => {
    //   this.annonces = result.data.allAnnonces;
    //   this.annoncesInfo = result.data.configurationGlobale.miniblocs.find(x=>x.id);
    //   //this.team = result.data.configurationGlobale.team[0];
    //   //console.log("TEAM : ", this.team);
    // });
  }

  // customOptions: OwlOptions = {
  //   loop: true,
	// 	nav: false,
	// 	dots: true,
	// 	autoplay: false,
	// 	smartSpeed: 1000,
	// 	autoplayTimeout: 5000,
	// 	responsive: {
	// 		0:{
  //       items:1,
  //     },
  //     768:{
  //       items:2,
  //     },
  //     1200:{
  //       items:4,
	// 		},
  //     1500:{
  //       items:5,
	// 		}
	// 	}
  // }

}
